<template>
    <div>
        <div class="bg-table mb-4">
            <table class="edit-table">
                <tr v-for="(price, index) in prices" :key="index">
                    <td>{{ price.title }}</td>
                    <td>
                        <div class="auto-scroll-vertical">{{ price.description }}</div>
                    </td>
                    <td>{{ price.amount }} грн.</td>
                    <td class="text-center">
                        <span class="btn-crl" @click="handleEditPrice(price, price._id ? price._id : index)">
                            <b-icon icon="pencil-fill"></b-icon>
                        </span>
                    </td>
                    <td class="text-center" @click="handleDeletePrice(price._id ? price._id : index)">
                        <span class="btn-crl">
                            <b-icon icon="x-circle-fill"></b-icon>
                        </span>
                    </td>
                </tr>
            </table>
            <edit-price :priceData="priceData" :prices="prices" @editPrice="editPrice"/>
        </div>
    </div>
</template>

<script>
    import EditPrice from "@/components/modals/EditPrice"

    export default {
        name: "PriceList",
        components: {
            EditPrice
        },
        props: {
            prices: {
                type: Array,
                required: true
            }
        },
        methods: {
            handleEditPrice(price, id){
                this.$bvModal.show('edit-price');
                this.priceData = price;
                this.priceData._id = id;
            },
            handleDeletePrice(id){
                this.$swal({
                    icon: 'warning',
                    text: 'Вы действительно хотите удалить билет?',
                    showCancelButton: true,
                    confirmButtonText: 'Да',
                    cancelButtonText: 'Отмена',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$emit('deletePrice', id)
                    }
                })
            },
            editPrice(price){
                this.$emit('editPrice', price);
            }
        },
        data(){
            return{
                priceData: {
                    _id: null,
                    title: null,
                    description: null,
                    amount: null
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .edit-table{
        tr{
            td{
                vertical-align: top;
                &:nth-child(1){
                    width: 20%;
                }
                &:nth-child(2){
                    width: 50%;
                }
            }
        }
        .auto-scroll-vertical{
            max-height: 90px;
            white-space: pre-line;
        }
    }
</style>