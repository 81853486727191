<template>
    <b-modal id="edit-price" size="lg" centered hide-footer>
        <div class="modal-wrapper">
            <div class="h1 text-center">Информация о билете</div>
            <price-form :isEdit="true" :priceData="priceData" :prices="prices" @editPrice="editPrice"/>
        </div>
    </b-modal>
</template>

<script>
    import PriceForm from '@/components/forms/price/PriceForm'

    export default {
        name: "EditPrice",
        props: {
            prices: {
                type: Array,
                required: true
            },
            priceData: {
                type: Object,
                required: true
            }
        },
        components: {
            PriceForm
        },
        methods: {
            editPrice(price){
                this.$emit('editPrice', price);
            }
        }
    }
</script>

<style scoped>

</style>