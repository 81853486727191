<template>
    <div class="upload-photo">
        <img class="upload-img" v-if="avatarSrc" :src="avatarSrc" alt="">
        <b-form-file
            accept=".jpg, .png, .jpeg"
            placeholder="Загрузить изображение"
            drop-placeholder="Перетащите файл сюда"
            v-model="avatar"
        >
            <template slot="file-name">
                Загрузить изображение
            </template>
        </b-form-file>
    </div>
</template>

<script>
    import { errorsAlert } from '@/helpers/errorsAlert'

    const base64Encode = data =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });

    export default {
        name: "UploadPhoto",
        props: ['value'],
        watch: {
            value(avatar){
                if(avatar && typeof avatar === 'string'){
                    this.avatarSrc = avatar
                }
                if(!avatar){
                    this.avatar = null;
                }
            },
            avatar(newValue, oldValue) {
                if (newValue !== oldValue) {
                    if (newValue) {
                        // Проверяем на максимальный размер картинку
                        const size = newValue.size;
                        const maxSize = 2097152;
                        if(size >= maxSize){
                            this.avatar = null;
                            errorsAlert('File too large. Max 2 MB');
                            return
                        }

                        base64Encode(newValue)
                            .then(value => {
                                this.avatarSrc = value;
                                this.$emit('input', newValue);
                            })
                            .catch(() => {
                                this.avatarSrc = null;
                            });
                    } else {
                        this.avatarSrc = null;
                    }
                }
            },
        },
        data(){
            return{
                avatar: null,
                avatarSrc: null,
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>