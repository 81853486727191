<template>
    <div>
        <material-list v-if="materials.length" :materials="materials" @deleteForumMaterial="deleteForumMaterial" @editForumMaterial="editForumMaterial"/>
        <material-form :isEdit="false" @addForumMaterial="addForumMaterial"/>
    </div>
</template>

<script>
    import MaterialList from '@/components/forms/material/MaterialList'
    import MaterialForm from '@/components/forms/material/MaterialForm'

    import { mapActions } from 'vuex'

    export default {
        name: "material",
        props: {
            materials: {
                type: Array,
                required: true
            }
        },
        components: {
            MaterialList,
            MaterialForm
        },
        methods: {
            ...mapActions([
                'getIconList'
            ]),
            addForumMaterial(material){
                this.$emit('addForumMaterial', material)
            },
            deleteForumMaterial(id){
                this.$emit('deleteForumMaterial', id)
            },
            editForumMaterial(material){
                this.$emit('editForumMaterial', material);
            },
        },
        mounted() {
            this.getIconList();
        }
    }
</script>

<style scoped>

</style>