<template>
    <div class="bg-table mb-4">
        <table class="edit-table">
            <tr v-for="(item, id) in scenes">
                <td>{{item.split(',')[0]}}</td>
                <td>{{item.split(',')[1]}}</td>
                <td class="text-center">
                    <span class="btn-crl" @click="handleEditScene(item, id)">
                        <b-icon icon="pencil-fill"></b-icon>
                    </span>
                </td>
                <td class="text-center">
                    <span class="btn-crl" @click="handleDeleteScene(id)">
                        <b-icon icon="x-circle-fill"></b-icon>
                    </span>
                </td>
            </tr>
        </table>
        <edit-scene :scenes="scenes" :sceneData="sceneData" @editScene="editScene"/>
    </div>
</template>

<script>
    import EditScene from '@/components/modals/EditScene'

    export default {
        name: "SceneList",
        components: {
            EditScene
        },
        props: {
            scenes: {
                type: Array,
                required: true
            }
        },
        methods: {
            editScene(scene){
                // this.scenes.splice(scene.id, 1, scene.scene);
                this.$emit('editScene',scene);
            },
            handleEditScene(scene, id){
                this.$bvModal.show('edit-scene');
                this.sceneData.id = id;
                this.sceneData.scene = scene;
            },
            handleDeleteScene(id){
                this.$swal({
                    icon: 'warning',
                    text: 'Вы действительно хотите удалить сцену?',
                    showCancelButton: true,
                    confirmButtonText: 'Да',
                    cancelButtonText: 'Отмена',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$emit('deleteScene', id);
                    }
                })
            }
        },
        data(){
            return{
                sceneData: {
                    id: null,
                    scene: ''
                }
            }
        }
    }
</script>

<style scoped>

</style>