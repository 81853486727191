<template>
    <div>
        <price-list v-if="prices.length" :prices="prices" class="mb-2" @deletePrice="deletePrice" @editPrice="editPrice"/>
        <price-form :isEdit="false" @addPrice="addPrice"/>
    </div>
</template>

<script>
    import PriceForm from "./PriceForm";
    import PriceList from "./PriceList";
    export default {
        name: "price",
        components: {
            PriceList,
            PriceForm
        },
        props: {
            prices: {
                type: Array,
                required: true
            }
        },
        methods: {
            addPrice(price){
                this.$emit('addPrice', price);
            },
            editPrice(price){
                this.$emit('editPrice', price);
            },
            deletePrice(id){
                this.$emit('deletePrice', id);
            }
        },
    }
</script>

<style scoped>

</style>