<template>
    <div>
        <speaker-list v-if="speakers.length" :isEdit="isEdit" :speakers="speakers" @editForumSpeaker="editForumSpeaker" @deleteForumSpeaker="deleteForumSpeaker"/>
        <speaker-form :isEdit="false" @addForumSpeaker="addForumSpeaker"/>
    </div>
</template>

<script>
    import SpeakerList from '@/components/forms/speaker/SpeakerList'
    import SpeakerForm from '@/components/forms/speaker/SpeakerForm'

    export default {
        name: "Speakers",
        components: {
            SpeakerList,
            SpeakerForm
        },
        props: {
            isEdit: {
                type: Boolean,
                required: true
            },
            speakers: {
                type: Array,
                required: true
            }
        },
        methods: {
            addForumSpeaker(speaker){
                this.$emit('addForumSpeaker', speaker);
            },
            editForumSpeaker(speaker){
                this.$emit('editForumSpeaker', speaker);
            },
            deleteForumSpeaker(id){
                this.$emit('deleteForumSpeaker', id);
            }
        }
    }
</script>

<style scoped>

</style>