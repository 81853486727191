<template>
    <div class="row align-items-start">
        <ValidationProvider rules="required" name="Дата и время начала ивента" tag="div" class="col-xl-6">
            <div class="form-group" slot-scope="{ valid, errors }">
                <label>Дата и время начала ивента</label>
                <date-picker
                    class="date-time-picker"
                    :input-class="errors[0] ? 'form-control is-invalid' : (valid ? 'form-control is-valid' : 'form-control')"
                    type="datetime"
                    v-model="range.dateStart"
                    format="DD.MM.YYYY HH:mm"
                    value-type="timestamp"
                    placeholder="__.__.____ __:__"
                    :default-value="new Date().setHours(new Date().getHours() + 1)"
                    :disabled-date="disableBeforeToday"
                    :disabled-time="disableAfterOneHourToday"
                ></date-picker>
                <b-form-input
                    :state="errors[0] ? false : (valid ? true : null)"
                    v-show="false"
                    type="date"
                >
                </b-form-input>
                <b-form-invalid-feedback>
                    {{ errors[0] }}
                </b-form-invalid-feedback>
            </div>
        </ValidationProvider>

        <ValidationProvider rules="" name="Дата и время окончания ивента" tag="div" class="col-xl-6">
            <div class="form-group" slot-scope="{ valid, errors, changed }">
                <label>Дата и время окончания ивента</label>
                <date-picker
                    class="date-time-picker"
                    :input-class="errors[0] ? 'form-control is-invalid' : (changed && valid ? 'form-control is-valid' : 'form-control')"
                    type="datetime"
                    v-model="range.dateEnd"
                    format="DD.MM.YYYY HH:mm"
                    value-type="timestamp"
                    placeholder="__.__.____ __:__"
                    :disabled="!range.dateStart"
                    :disabled-date="disableBeforeDateStart"
                    :disabled-time="disableBeforeTimeStart"
                    :default-value="defaultValueDateEnd"
                ></date-picker>
                <b-form-input
                    :state="errors[0] ? false : (valid ? true : null)"
                    v-show="false"
                    type="date"
                >
                </b-form-input>
                <b-form-invalid-feedback>
                    {{ errors[0] }}
                </b-form-invalid-feedback>
            </div>
        </ValidationProvider>
    </div>
</template>

<script>
    export default {
        name: "DateTimeRange",
        props: {
            value: {
                type: Object
            }
        },
        watch: {
            value(range){
                this.range.dateStart = range.dateStart;
                this.range.dateEnd = range.dateEnd;
            },
            'range.dateStart'(newDate, oldDate) {
                if(!newDate){
                    this.range.dateEnd = null;
                }
                if(newDate !== oldDate){
                    this.range.dateStart = newDate;
                    this.$emit('input', this.range);

                    let hours = new Date(newDate).getHours() + 1;
                    let minutes = new Date(newDate).getMinutes();
                    this.defaultValueDateEnd = new Date().setHours(hours, minutes, 0, 0);
                }
            },
            'range.dateEnd'(newDate, oldDate) {
                if(newDate !== oldDate){
                    this.range.dateEnd = newDate;
                    this.$emit('input', this.range);
                }
            },
        },
        methods: {
            //Блокируем выбор даты до текущего дня
            disableBeforeToday(date){
                const today = new Date().getTime() - 3600 * 1000 * 24;
                return date <= today;
            },
            //Блокируем выбор времени за час от текущего времени
            disableAfterOneHourToday(time){
                let hours = new Date().getHours() + 1;
                let minutes = new Date().getMinutes();
                return time <= new Date().setHours(hours, minutes, 0, 0);
            },
            disableBeforeDateStart(date){
                return date <= this.range.dateStart - 3600 * 1000 * 24;
            },
            disableBeforeTimeStart(time){
                let hours = new Date(this.range.dateStart).getHours() + 1;
                let minutes = new Date(this.range.dateStart).getMinutes() - 1;
                return time <= new Date(this.range.dateStart).setHours(hours, minutes, 0, 0);
            },
        },
        data(){
            return {
                isEdit: true,
                range: {
                    dateStart: null,
                    dateEnd:  null
                },
                defaultValueDateEnd: null
            }
        }
    }
</script>

<style lang="scss" scoped>
    .date-time-picker{
        width: 100%;
        max-width: 210px;
        min-width: 170px;
    }

</style>
