<template>
    <b-modal id="edit-speaker" size="lg" centered hide-footer>
        <div class="modal-wrapper">
            <div class="h1">Информация о спикере</div>
            <speaker-form :isEdit="true" :speakerData="speakerData" :speakers="speakers" @editForumSpeaker="editForumSpeaker"/>
        </div>
    </b-modal>
</template>

<script>
    import SpeakerForm from '@/components/forms/speaker/SpeakerForm'

    export default {
        name: "EditScene",
        props: {
            speakers: {
                type: Array,
                required: true
            },
            speakerData: {
                type: Object,
                required: true
            }
        },
        components: {
            SpeakerForm
        },
        methods: {
            editForumSpeaker(speaker){
                this.$emit('editForumSpeaker', speaker);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .h1{
        text-align: center;
        margin-bottom: 30px;
    }
</style>