<template>
    <div>
        <div v-if="isEdit" class="modal-form">
            <div class="material-row">
                <div class="material-avatar" :class="selectedIcon ? 'selected' : ''">
                    <v-select
                        class="select-icon"
                        placeholder=""
                        :options="icons"
                        v-model="selectedIcon"
                        label="icon"
                        :searchable="false"
                        :class="!isValidIcon ? 'is-invalid' : ''"
                    >
                        <template #no-options="{ search, searching, loading }">
                            Извините, нет доступных иконок
                        </template>

                        <template #selected-option="{ icon }">
                            <img :src="icon" alt="">
                        </template>

                        <template #option="{ icon, title }">
                            <div class="icon-item" :title="title">
                                <img :src="icon" alt="">
                            </div>
                        </template>
                    </v-select>
                </div>
                <div class="material-desc">
                    <div class="form-group">
                        <b-form-input
                            placeholder="Название материалов"
                            v-model="material.title"
                            :class="!isValidTitle ? 'is-invalid' : ''"
                            maxlength="100"
                        ></b-form-input>
                        <div v-if="!isValidTitle" class="invalid-feedback">Введите название материала (не менее 3 символов)</div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <b-form-input
                        placeholder="Ссылка на папку с материалами"
                        v-model="material.url"
                        type="url"
                        :class="!isValidUrl ? 'is-invalid' : ''"
                        maxlength="1000"
                ></b-form-input>
                <div v-if="!isValidUrl" class="invalid-feedback">Введите ссылку на папку с материалами (начиная с https:// или http://)</div>
            </div>
            <button type="button" class="btn btn-primary w-100" @click="handleEditMaterial">Сохранить</button>
        </div>
        <div v-else>
            <div class="material-row">
                <div class="material-avatar" :class="selectedIcon ? 'selected' : ''">
                    <v-select
                        class="select-icon"
                        placeholder=""
                        :options="icons"
                        v-model="selectedIcon"
                        label="icon"
                        :searchable="false"
                        :class="!isValidIcon ? 'is-invalid' : ''"
                    >
                        <template #no-options="{ search, searching, loading }">
                            Извините, нет доступных иконок
                        </template>

                        <template #selected-option="{ icon }">
                            <img :src="icon" alt="">
                        </template>

                        <template #option="{ icon, title }">
                            <div class="icon-item" :title="title">
                                <img :src="icon" alt="">
                            </div>
                        </template>
                    </v-select>
                </div>
                <div class="material-desc">
                    <div class="row">
                        <div class="col-md-6 form-group">
                            <b-form-input
                                placeholder="Название материалов"
                                v-model="material.title"
                                :class="!isValidTitle ? 'is-invalid' : ''"
                                maxlength="100"
                            ></b-form-input>
                            <div v-if="!isValidTitle" class="invalid-feedback">Введите название материала (не менее 3 символов)</div>
                        </div>
                        <div class="col-md-6 form-group">
                            <b-form-input
                                placeholder="Ссылка на папку с материалами"
                                v-model="material.url"
                                type="url"
                                :class="!isValidUrl ? 'is-invalid' : ''"
                                maxlength="1000"
                            ></b-form-input>
                            <div v-if="!isValidUrl" class="invalid-feedback">Введите ссылку на папку с материалами (начиная с https:// или http://)</div>
                        </div>
                    </div>
                </div>
            </div>
            <button type="button" class="add-btn" @click="handleAddMaterial">
                <b-icon icon="plus-circle-fill"></b-icon>
                <span>Добавить материал</span>
            </button>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    const urlExp = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    export default {
        name: "MaterialForm",
        props: {
            isEdit: {
                type: Boolean,
                required: true
            },
            materialData: {
                type: Object,
                required: false
            },
        },
        computed: {
            ...mapGetters([
                'icons'
            ]),
            isValidMaterial(){
                if(!this.selectedIcon){
                    this.isValidIcon = false;
                }

                if(!this.material.title || this.material.title.length < 3){
                    this.isValidTitle = false;
                }

                const urlRegex = new RegExp(urlExp);
                if(!this.material.url || !this.material.url.match(urlRegex)){
                    this.isValidUrl = false;
                }

                if(!this.isValidIcon || !this.isValidTitle || !this.isValidUrl){
                    return false;
                }

                return true;
            },
        },
        methods: {
            parseMaterialData(){
                if(this.materialData){
                    this.material.title = this.materialData.title;
                    this.material.url = this.materialData.url;
                    this.material.icon = this.materialData.icon;

                    // this.selectedIcon = this.materialData.src ? this.materialData.src : this.materialData.icon.icon;
                    this.selectedIcon = this.materialData.src ? this.materialData.src : this.materialData.icon ? this.materialData.icon.icon : this.defaultIcon;
                }
            },
            handleAddMaterial(){
                if(this.isValidMaterial){
                    const data = {
                        title: this.material.title,
                        url: this.material.url,
                        icon: this.selectedIcon._id,
                        src: this.selectedIcon.icon
                    };
                    this.$emit('addForumMaterial', data);

                    this.material.title = null;
                    this.material.url = null;
                    this.material.icon = null;
                    this.selectedIcon = null;
                }
            },
            handleEditMaterial(){
                if(this.isValidMaterial){
                    const data = {
                        _id: this.materialData._id,
                        title: this.material.title,
                        url: this.material.url,
                    };

                    if(this.selectedIcon.icon){
                        data.src = this.selectedIcon.icon;
                        data.icon = this.selectedIcon._id;
                    }

                    this.$emit('editForumMaterial', data);
                    this.$bvModal.hide('edit-material');
                }
            }
        },
        watch: {
            selectedIcon(value) {
                if (value) {
                    this.isValidIcon = true;
                }
            },
            'material.title'(value) {
                if(value && value.length >= 3){
                    this.isValidTitle = true;
                }
            },
            'material.url'(value) {
                const urlRegex = new RegExp(urlExp);
                if(value && value.match(urlRegex)){
                    this.isValidUrl = true;
                }
            },
        },
        created(){
            this.parseMaterialData();
        },
        data(){
            return {
                selectedIcon: null,
                material: {
                    title: null,
                    url: null,
                    icon: null
                },
                isValidIcon: true,
                isValidTitle: true,
                isValidUrl: true,
                defaultIcon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAEhQTFRFAAAALGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4ssB2BgAAABh0Uk5TABqw+v/ZpECm2HMN1D2JaZdxMJzFDMaAdt8bswAAAHhJREFUeJztkkkOgCAMRQuCoDjgyP1vKoMhERqJK134FqRpX7sgH+AjEFqxjIqSKNB87KBRQPb9jSjgc8Z+4VbgdUEQIHGhCZstgOpQoYfBvtI1BCaMttCMh86UC9pXcj5bSyqsG1xQeyIYSDCPPqoYuWJoi7F/mQNm8gjj30+HFQAAAABJRU5ErkJggg=='
            }
        }
    }
</script>

<style lang="scss" scoped>
    .material-row{
        display: flex;
    }
    .material-avatar{
        width: 48px;
        height: 48px;
        flex: none;
        position: relative;
        margin-bottom: 1rem;
    }
    .material-desc{
        flex: 1;
        padding: 5px 0 0 25px;
    }
    .modal-form {
        max-width: 480px;
        margin: 0 auto 40px;
    }
</style>