<template>
    <b-modal id="edit-material" size="lg" centered hide-footer>
        <div class="modal-wrapper">
            <div class="h1 text-center">Информация о материале</div>
            <material-form :isEdit="true" :materialData="materialData" :materials="materials" @editForumMaterial="editForumMaterial"/>
        </div>
    </b-modal>
</template>

<script>
    import MaterialForm from '@/components/forms/material/MaterialForm'

    export default {
        name: "EditMaterial",
        props: {
            materials: {
                type: Array,
                required: true
            },
            materialData: {
                type: Object,
                required: true
            }
        },
        components: {
            MaterialForm
        },
        methods: {
            editForumMaterial(material){
                this.$emit('editForumMaterial', material);
            }
        }
    }
</script>

<style scoped>

</style>