var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isEdit)?_c('div',{staticClass:"modal-form"},[_c('div',{staticClass:"material-row"},[_c('div',{staticClass:"material-avatar",class:_vm.selectedIcon ? 'selected' : ''},[_c('v-select',{staticClass:"select-icon",class:!_vm.isValidIcon ? 'is-invalid' : '',attrs:{"placeholder":"","options":_vm.icons,"label":"icon","searchable":false},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Извините, нет доступных иконок ")]}},{key:"selected-option",fn:function(ref){
var icon = ref.icon;
return [_c('img',{attrs:{"src":icon,"alt":""}})]}},{key:"option",fn:function(ref){
var icon = ref.icon;
var title = ref.title;
return [_c('div',{staticClass:"icon-item",attrs:{"title":title}},[_c('img',{attrs:{"src":icon,"alt":""}})])]}}],null,false,3171984804),model:{value:(_vm.selectedIcon),callback:function ($$v) {_vm.selectedIcon=$$v},expression:"selectedIcon"}})],1),_c('div',{staticClass:"material-desc"},[_c('div',{staticClass:"form-group"},[_c('b-form-input',{class:!_vm.isValidTitle ? 'is-invalid' : '',attrs:{"placeholder":"Название материалов","maxlength":"100"},model:{value:(_vm.material.title),callback:function ($$v) {_vm.$set(_vm.material, "title", $$v)},expression:"material.title"}}),(!_vm.isValidTitle)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("Введите название материала (не менее 3 символов)")]):_vm._e()],1)])]),_c('div',{staticClass:"form-group"},[_c('b-form-input',{class:!_vm.isValidUrl ? 'is-invalid' : '',attrs:{"placeholder":"Ссылка на папку с материалами","type":"url","maxlength":"1000"},model:{value:(_vm.material.url),callback:function ($$v) {_vm.$set(_vm.material, "url", $$v)},expression:"material.url"}}),(!_vm.isValidUrl)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("Введите ссылку на папку с материалами (начиная с https:// или http://)")]):_vm._e()],1),_c('button',{staticClass:"btn btn-primary w-100",attrs:{"type":"button"},on:{"click":_vm.handleEditMaterial}},[_vm._v("Сохранить")])]):_c('div',[_c('div',{staticClass:"material-row"},[_c('div',{staticClass:"material-avatar",class:_vm.selectedIcon ? 'selected' : ''},[_c('v-select',{staticClass:"select-icon",class:!_vm.isValidIcon ? 'is-invalid' : '',attrs:{"placeholder":"","options":_vm.icons,"label":"icon","searchable":false},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Извините, нет доступных иконок ")]}},{key:"selected-option",fn:function(ref){
var icon = ref.icon;
return [_c('img',{attrs:{"src":icon,"alt":""}})]}},{key:"option",fn:function(ref){
var icon = ref.icon;
var title = ref.title;
return [_c('div',{staticClass:"icon-item",attrs:{"title":title}},[_c('img',{attrs:{"src":icon,"alt":""}})])]}}]),model:{value:(_vm.selectedIcon),callback:function ($$v) {_vm.selectedIcon=$$v},expression:"selectedIcon"}})],1),_c('div',{staticClass:"material-desc"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 form-group"},[_c('b-form-input',{class:!_vm.isValidTitle ? 'is-invalid' : '',attrs:{"placeholder":"Название материалов","maxlength":"100"},model:{value:(_vm.material.title),callback:function ($$v) {_vm.$set(_vm.material, "title", $$v)},expression:"material.title"}}),(!_vm.isValidTitle)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("Введите название материала (не менее 3 символов)")]):_vm._e()],1),_c('div',{staticClass:"col-md-6 form-group"},[_c('b-form-input',{class:!_vm.isValidUrl ? 'is-invalid' : '',attrs:{"placeholder":"Ссылка на папку с материалами","type":"url","maxlength":"1000"},model:{value:(_vm.material.url),callback:function ($$v) {_vm.$set(_vm.material, "url", $$v)},expression:"material.url"}}),(!_vm.isValidUrl)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("Введите ссылку на папку с материалами (начиная с https:// или http://)")]):_vm._e()],1)])])]),_c('button',{staticClass:"add-btn",attrs:{"type":"button"},on:{"click":_vm.handleAddMaterial}},[_c('b-icon',{attrs:{"icon":"plus-circle-fill"}}),_c('span',[_vm._v("Добавить материал")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }