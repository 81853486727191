<template>
    <div class="bg-table mb-4 auto-scroll-vertical">
        <table class="edit-table text-break">
            <tr v-for="(material, index) in materials">
                <td class="w-25">
                    <div class="material-title">
                        <div class="material-title__img">
                            <img :src="material.src ? material.src : (material.icon ? material.icon.icon : defaultIcon)" alt="">
                        </div>
                        <div class="material-title__name">{{material.title}}</div>
                    </div>
                </td>
                <td>{{material.url}}</td>
                <td class="text-center">
                    <span class="btn-crl" @click="handleEditMaterial(material, material._id ? material._id : index)">
                        <b-icon icon="pencil-fill"></b-icon>
                    </span>
                </td>
                <td class="text-center" @click="handleDeleteMaterial(material._id ? material._id : index)">
                    <span class="btn-crl">
                        <b-icon icon="x-circle-fill"></b-icon>
                    </span>
                </td>
            </tr>
        </table>
        <edit-material :materialData="materialData" :materials="materials" @editForumMaterial="editForumMaterial"/>
    </div>
</template>

<script>
    import EditMaterial from '@/components/modals/EditMaterial'

    export default {
        name: "MaterialList",
        props: {
            materials: {
                type: Array,
                required: true
            }
        },
        components: {
            EditMaterial
        },
        methods: {
            handleEditMaterial(material, id){
                this.$bvModal.show('edit-material');
                this.materialData = material;
                this.materialData._id = id;
            },
            handleDeleteMaterial(id){
                this.$swal({
                    icon: 'warning',
                    text: 'Вы действительно хотите удалить материал?',
                    showCancelButton: true,
                    confirmButtonText: 'Да',
                    cancelButtonText: 'Отмена',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$emit('deleteForumMaterial', id)
                    }
                })
            },
            editForumMaterial(material){
                this.$emit('editForumMaterial', material);
            }
        },
        data(){
            return{
                materialData: {
                    _id: null,
                    title: null,
                    url: null,
                    icon: null
                },
                defaultIcon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAEhQTFRFAAAALGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4LGi4ssB2BgAAABh0Uk5TABqw+v/ZpECm2HMN1D2JaZdxMJzFDMaAdt8bswAAAHhJREFUeJztkkkOgCAMRQuCoDjgyP1vKoMhERqJK134FqRpX7sgH+AjEFqxjIqSKNB87KBRQPb9jSjgc8Z+4VbgdUEQIHGhCZstgOpQoYfBvtI1BCaMttCMh86UC9pXcj5bSyqsG1xQeyIYSDCPPqoYuWJoi7F/mQNm8gjj30+HFQAAAABJRU5ErkJggg=='
            }
        }
    }
</script>

<style lang="scss" scoped>
    .auto-scroll-vertical{
        max-height: 365px;
    }
    .material-title{
        display: flex;
        align-items: center;
        min-height: 48px;
        &__img{
            width: 48px;
            height: 48px;
            flex: none;
            border-radius: 50%;
            background: #fff;
            padding: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                max-width: 100%;
            }
        }
        &__name{
            flex: 1;
            padding-left: 15px;
        }
    }
</style>