<template>
    <div>
        <div class="row">
            <div class="col-md-6 form-group">
                <b-form-input
                    placeholder="Название Билета"
                    v-model="price.title"
                    :class="!isValidTitle ? 'is-invalid' : ''"
                    maxlength="100"
                ></b-form-input>
                <div v-if="!isValidTitle" class="invalid-feedback">Введите Название Билета (не менее 3 символов)</div>
            </div>
            <div class="col-md-6 form-group">
                <b-form-input
                    placeholder="Цена Билета"
                    v-model="price.amount"
                    :class="!isValidAmount ? 'is-invalid' : ''"
                ></b-form-input>
                <div v-if="!isValidAmount" class="invalid-feedback">{{errorAmount}}</div>
            </div>
        </div>
        <div class="form-group">
            <b-form-textarea
                placeholder="Описание Билета"
                v-model="price.description"
                :class="!isValidDescription ? 'is-invalid' : ''"
                maxlength="1000"
            ></b-form-textarea>
            <div v-if="!isValidDescription" class="invalid-feedback">Введите Описание Билета (не менее 3 символов)</div>
        </div>

        <div v-if="isEdit" class="text-center">
            <button type="button" class="btn btn-primary pl-5 pr-5" @click="handleEditPrice">Сохранить</button>
        </div>
        <button v-else type="button" class="add-btn" @click="handleAddPrice">
            <b-icon icon="plus-circle-fill"></b-icon>
            <span>Добавить Билет</span>
        </button>
    </div>
</template>

<script>
    const decimalExp = /^(\d+(?:[.]\d{1,2})?)$/;

    export default {
        name: "PriceForm",
        props: {
            isEdit: {
                type: Boolean,
                required: true
            },
            priceData: {
                type: Object
            }
        },
        computed: {
            isValidPrice(){
                if(!this.price.title || this.price.title.length < 3){
                    this.isValidTitle = false;
                }

                if(!this.price.description || this.price.description.length < 3){
                    this.isValidDescription = false;
                }

                const decimalRegex = new RegExp(decimalExp);
                if(!this.price.amount || !this.price.amount.toString().match(decimalRegex)){
                    this.isValidAmount = false;
                    this.errorAmount = this.messAmount.required;
                }

                if(this.price.amount > 1000000000){
                    this.isValidAmount = false;
                    this.errorAmount = this.messAmount.max;
                }

                if(!this.isValidTitle || !this.isValidDescription || !this.isValidAmount){
                    return false;
                }

                return true;
            },
        },
        methods: {
            parsePriceData(){
                if(this.priceData){
                    for(let field in this.priceData){
                        this.price[field] = this.priceData[field]
                    }
                }
            },
            handleAddPrice(){
                if(this.isValidPrice){
                    this.$emit('addPrice', Object.assign({}, this.price));
                    this.resetForm();
                }
            },
            handleEditPrice(){
                if(this.isValidPrice){
                    this.$emit('editPrice', Object.assign({}, this.price));
                    this.$bvModal.hide('edit-price');
                }
            },
            resetForm(){
                for(let field in this.price){
                    this.price[field] = null;
                }
            }
        },
        watch: {
            'price.title'(value){
                if(value && value.length >= 3){
                    this.isValidTitle = true;
                }
            },
            'price.description'(value){
                if(value && value.length >= 3){
                    this.isValidDescription = true;
                }
            },
            'price.amount'(value) {
                const decimalRegex = new RegExp(decimalExp);
                if(value && value.toString().match(decimalRegex)){
                    this.isValidAmount = true;
                    this.errorAmount = this.messAmount.required;
                }
                if(value > 1000000000){
                    this.isValidAmount = true;
                    this.errorAmount = this.messAmount.max;
                }
            },
        },
        created(){
            this.parsePriceData()
        },
        data(){
            return{
                price: {
                    title: null,
                    description: null,
                    amount: null
                },
                isValidTitle: true,
                isValidDescription: true,
                isValidAmount: true,
                messAmount: {
                    required: 'Поле Стоимость Билета может содержать только цифры, разделенные \'.\'',
                    max: 'Максимальная стоимость ивента 1 000 000 000 грн.'
                },
                errorAmount: ''
            }
        }
    }
</script>

<style scoped>

</style>