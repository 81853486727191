<template>
    <div class="wrapper">
        <div class="h1">Основная информация</div>
        <ValidationObserver ref="Forum">
            <b-form slot-scope="{ validate }">
                <div class="avatar-box">
                    <div class="avatar-box__img">
                        <upload-photo v-model="forumAvatar"/>
                    </div>
                    <div class="avatar-box__desc">
                        <label>Название ивента</label>
                        <div class="row">
                            <ValidationProvider rules="required|min:5|max:100" name="Название ивента на украинском" tag="div" class="col-xl-4">
                                <div class="form-group" slot-scope="{ valid, errors }">
                                    <b-form-input
                                        v-model="forum.title"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        placeholder="Название ивента на украинском"
                                    ></b-form-input>
                                    <b-form-invalid-feedback>
                                    {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </div>
                            </ValidationProvider>

                            <ValidationProvider rules="min:5|max:100" name="Название ивента на английском" tag="div" class="col-xl-4">
                                <div class="form-group" slot-scope="{ valid, errors, changed }">
                                    <b-form-input
                                        v-model="forum.enTitle"
                                        :state="errors[0] ? false : (changed && valid ? true : null)"
                                        placeholder="Название ивента на английском"
                                    ></b-form-input>
                                    <b-form-invalid-feedback>
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </div>
                            </ValidationProvider>

                            <ValidationProvider rules="min:5|max:50" name="Короткое название ивента" tag="div" class="col-xl-4">
                                <div class="form-group" slot-scope="{ valid, errors, changed }">
                                    <b-form-input
                                        v-model="forum.shortTitle"
                                        :state="errors[0] ? false : (changed && valid ? true : null)"
                                        placeholder="Короткое название ивента"
                                    ></b-form-input>
                                    <b-form-invalid-feedback>
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="row picker-row">
                            <!-- <div class="col-xl-7">
                                <date-time-range v-model="rangeDateTime"/>
                            </div>
                            <ValidationProvider rules="required" name="Тип ивента" tag="div" class="col-xl-5">
                                <div class="form-group" slot-scope="{ valid, errors }">
                                    <label>Тип ивента</label>
                                    <b-form-radio v-model="forum.top" name="top" value="true">Форум</b-form-radio>
                                    <b-form-radio v-model="forum.top" name="top" value="false">Бизнес ивент</b-form-radio>
                                    <b-form-invalid-feedback :state="errors[0] ? false : (valid ? true : null)">{{ errors[0] }}</b-form-invalid-feedback>
                                </div>
                            </ValidationProvider> -->

                            <date-time-range v-model="rangeDateTime" class="picker-cell"/>
                            <ValidationProvider rules="required" name="Тип ивента" tag="div" class="type-cell">
                                <div class="form-group" slot-scope="{ valid, errors }">
                                    <label>Тип ивента</label>
                                    <b-form-radio v-model="forum.top" name="top" value="true">Форум</b-form-radio>
                                    <b-form-radio v-model="forum.top" name="top" value="false">Бизнес ивент</b-form-radio>
                                    <b-form-invalid-feedback :state="errors[0] ? false : (valid ? true : null)">{{ errors[0] }}</b-form-invalid-feedback>
                                </div>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <ValidationProvider rules="required|min:1|max:1500" name="Описание ивента" tag="div" class="col-xl-10 col-md-12">
                        <div class="form-group" slot-scope="{ valid, errors }">
                            <label>Описание ивента (до 1500 символов)</label>
                            <div class="textarea-wrapper">
                                <b-form-textarea
                                    v-model="forum.description"
                                    name="description"
                                    placeholder="Опишите ваш ивент"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    maxlength="1500"
                                ></b-form-textarea>
                                <b-form-invalid-feedback>
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                                <div v-if="forum.description" class="text-count" :class="forum.description.length >= 1500 ? 'text-danger' : ''">
                                    <span v-if="forum.description.length >= 1500">макс. </span>{{forum.description.length}} <span v-if="forum.description.length >= 1500">символов</span>
                                </div>
                            </div>
                        </div>
                    </ValidationProvider>
                </div>
                <div class="scene-box">
                    <label v-if="scenes.length">Сцены ивента</label>
                    <div class="row">
                        <div class="col-xl-10 col-md-12">
                            <scenes :scenes="scenes" @addScene="addScene" @editScene="editScene" @deleteScene="deleteScene"/>
                        </div>
                    </div>
                </div>

                <div class="speaker-box">
                    <label>Спикеры ивента</label>
                    <div class="row">
                        <div class="col-xl-10 col-md-12">
                            <speakers :isEdit="isEdit" :speakers="speakers" @addForumSpeaker="addForumSpeaker" @editForumSpeaker="editForumSpeaker" @deleteForumSpeaker="deleteForumSpeaker"/>
                        </div>
                    </div>
                </div>

                <div class="material-box">
                    <label>Материалы ивента</label>
                    <div class="row">
                        <div class="col-xl-10 col-md-12">
                            <material
                                :materials="materials"
                                @addForumMaterial="addForumMaterial"
                                @deleteForumMaterial="deleteForumMaterial"
                                @editForumMaterial="editForumMaterial"
                            />
                        </div>
                    </div>
                </div>

                <div class="location-box">
                    <div class="row">
                        <ValidationProvider rules="required|min:1|max:250" name="Адрес ивента" tag="div" class="col-xl-4">
                            <div class="form-group" slot-scope="{ valid, errors }">
                                <label>Адрес ивента</label>
                                <b-form-input
                                    v-model="forum.location"
                                    placeholder="Адрес ивента"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <b-form-invalid-feedback>
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>

                        <div class="col-md-8 d-none">
                            <label>Координаты на карте</label>
                            <div class="row">
                                <div class="col-md-6 form-group">
                                    <b-form-input v-model="latitude" name="latitude" placeholder="Широта"></b-form-input>
                                </div>
                                <div class="col-md-6 form-group">
                                    <b-form-input v-model="longitude" name="longitude" placeholder="Долгота"></b-form-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="price-box">
                    <label>Описание и цена билетов</label>
                    <div class="row">
                        <div class="col-xl-10 col-md-12">
                            <price :prices="prices" @addPrice="addPrice" @deletePrice="deletePrice" @editPrice="editPrice"/>
                        </div>
                    </div>
                </div>

                <b-button type="button" variant="primary submit mr-3" :disabled="isBusy" @click="handleSubmit(false)">
                    <b-spinner v-if="isBusy" small type="grow"></b-spinner>
                    <span class="btn-txt" v-if="isBusy">Сохранение...</span>
                    <span class="btn-txt" v-else>Опубликовать</span>
                </b-button>

                <b-button type="button" variant="outline-primary submit" :disabled="isBusy" @click="handleSubmit(true)">
                    <b-spinner v-if="isBusy" small type="grow"></b-spinner>
                    <span class="btn-txt" v-if="isBusy">Сохранение...</span>
                    <span class="btn-txt" v-else>сохранить как черновик</span>
                </b-button>
            </b-form>
        </ValidationObserver>
        <success-forum :forum="forum" @reset="resetForm"/>
    </div>
</template>

<script>
    import { mapActions, mapMutations, mapState } from 'vuex'

    import UploadPhoto from '@/components/forms/UploadPhoto'
    import Scenes from '@/components/forms/scene/Scenes'
    import Speakers from '@/components/forms/speaker/Speakers'
    import DateTimeRange from '@/components/forms/DateTimeRange'
    import Material from '@/components/forms/material'
    import SuccessForum from '@/components/modals/SuccessForum'
    import { errorsAlertApi } from '@/helpers/errorsAlertApi'
    import { extend } from 'vee-validate';
    import Price from "../price/index";

    extend("decimal", {
        validate(value) {
            const regex = new RegExp(/^(\d+(?:[.]\d{1,2})?)$/);
            return value.toString().match(regex)
        },
        message: field => `Поле ${field} может содержать только цифры, разделенные '.'`,
    });

    export default {
        name: "MainForumInfo",
        components: {
            Price,
            UploadPhoto,
            Speakers,
            Scenes,
            DateTimeRange,
            Material,
            SuccessForum
        },
        props: {
            // Редактируем или добавляем новый форум
            isEdit: {
                type: Boolean,
                required: true
            },
            forumData: {
                type: Object
            }
        },
        computed: {
            ...mapState([
                'forums'
            ]),
            getEditData(){
                const data = this.forumData;
                this.forumAvatar = data.imagePath;
                this.forum.title = data.title;
                this.forum.enTitle = data.enTitle;
                this.forum.shortTitle = data.shortTitle;

                const dateData = {
                    dateStart: data.dateStart,
                    dateEnd: data.dateEnd
                };
                this.rangeDateTime = dateData;
                this.forum.top = data.top;
                this.forum.description = data.description;
                this.scenes = data.rooms ? data.rooms : [];
                this.speakers = data.speakers;
                this.materials = data.materials;
                this.forum.location = data.location;

                if(data.coordinates){
                    const coordinates = data.coordinates.split(',');
                    this.latitude = coordinates[0];
                    this.longitude = coordinates[1];
                }

                this.prices = data.price;

                this.forum._id = data._id;
            },
        },
        methods: {
            ...mapActions([
                'saveNewForum',
                'saveNewSpeaker',
                'saveForumImg',
                'saveEditForum',
                'saveEditSpeaker',
                'deleteSpeakerById',
                'saveSpeakerPhoto',
                'saveMaterials',
                'deleteMaterialById',
                'editMaterialById'
            ]),
            ...mapMutations([
                'setSpeakerByForumId',
            ]),
            resetForm(){
                // Очистить поля формы
                this.forumAvatar = null;
                this.forum = {
                    shortTitle: null,
                    title: null,
                    enTitle: null,
                    description: null,
                    top: true,
                    price: null,
                    location: null,
                    dateStart: null,
                    rooms: [],
                    draft: true
                };
                this.rangeDateTime = {
                    dateStart: null,
                    dateEnd: null
                };
                this.scenes = [];
                this.prices = [];
                // this.coordinates = null;

                this.$nextTick(() => {
                    this.$refs.Forum.reset();
                });
            },
            addScene(scene){
                this.scenes.push(scene);
            },
            editScene(scene){
                this.scenes.splice(scene.id, 1, scene.scene);
            },
            deleteScene(id){
                this.scenes.splice(id, 1);
            },
            addForumSpeaker(newSpeaker){
                this.speakers = this.speakers.concat([ newSpeaker ]);

                if(this.isEdit){
                    this.addedSpeakers.push(newSpeaker);
                }
            },
            editForumSpeaker(editSpeaker){
                if(this.isEdit){
                    //Проверяем id на тип. Когда строка то спикер уже добавлен и разрешаем его редактирование
                    if(typeof editSpeaker._id === 'string'){
                        this.editedSpeakers[editSpeaker._id] = editSpeaker;
                    }
                }

                // Добавляем изменения в текущему спикеру
                for (let i = 0, len = this.speakers.length; i < len; i++) {
                    const speaker = this.speakers[i];
                    if(speaker._id === editSpeaker._id || editSpeaker._id === i){
                        for(let key of Object.keys(editSpeaker)){
                            this.speakers[i][key] = editSpeaker[key]
                        }
                        break;
                    }
                }
            },
            deleteForumSpeaker(id){
                if(this.isEdit){
                    // Если speaker id строка (например '5fdb0cf823170156e652ac81' уже добавленнный спикер
                    if(typeof id === 'string'){
                        this.deletedSpeakers.push(id);

                        this.speakers = this.speakers.filter(item => {
                            return item._id !== id
                        });
                    } else {
                        // Если speaker id число (когда добавляем нового спикера при редактировании форума)
                        this.speakers.splice(id, 1);
                        this.addedSpeakers.splice(id - 1, 1);
                    }
                } else {
                    this.speakers.splice(id, 1);
                }
            },
            addForumMaterial(newMaterial){
                this.materials = this.materials.concat([ newMaterial ]);
                if(this.isEdit){
                    this.addedMaterials.push(newMaterial);
                }
            },
            deleteForumMaterial(id){
                if(this.isEdit){
                    // Если material id строка (например '5fdb0cf823170156e652ac81' уже добавленнный спикер
                    if(typeof id === 'string'){
                        this.deletedMaterials.push(id);

                        this.materials = this.materials.filter(item => {
                            return item._id !== id
                        });
                    } else {
                        // Если число (когда добавляем нового спикера при редактировании форума)
                        this.materials.splice(id, 1);
                        this.addedMaterials.splice(id - 1, 1);
                    }
                } else {
                    this.materials.splice(id, 1);
                }
            },
            editForumMaterial(material){
                if(this.isEdit){
                    // Проверяем id на тип. Когда строка то материал уже добавлен и разрешаем его редактирование
                    if(typeof material._id === 'string'){
                        this.editedMaterials[material._id] = material;
                    }
                }

                // Добавляем изменения в текущему спикеру
                this.materials.find((item, index) => {
                    // Фильтруем по material id (может быть как '5fdb0cf823170156e652ac81' так и просто число (когда добавляем нового спикера при редактировании форума))
                    if(item._id === material._id || material._id === index){
                        Object.assign(this.materials[index], material)
                    }
                });
            },
            addPrice(price){
                this.prices.push(price)
            },
            deletePrice(id){
                this.prices = this.prices.filter((price, index) => {
                    if(price.hasOwnProperty('_id')){
                        return price._id !== id
                    }
                    return index !== id
                });
            },
            editPrice(price){
                this.prices.find((item, index) => {
                    if(price._id === item._id){
                        this.$set(this.prices, index, price)
                    }
                });
            },
            handleSubmit(draft) {
                this.$refs.Forum.validate().then(isValid => {
                    if(isValid){
                        // Показывам лоадер на кнопке submit
                        this.isBusy = true;
                        this.forum.rooms = this.scenes;
                        this.forum.price = this.prices;
                        // Добавляем параметр координат
                        if(this.latitude && this.longitude){
                            this.forum.coordinates = `${this.latitude},${this.longitude}`;
                        }
                        // Публикуем или сохраняем как черновик
                        this.forum.draft = draft;

                        if(this.forum.enTitle === ''){
                            this.forum.enTitle = null
                        }

                        if(this.forum.shortTitle === ''){
                            this.forum.shortTitle = null
                        }

                        //Редактируем форум
                        if(this.isEdit){
                            const forumId = this.$route.params.forumId;

                            // Редактируем сам форум
                            const forumEditData = {
                                forumId,
                                forum: this.forum
                            };
                            this.saveEditForum(forumEditData).then(() => {

                                // Екшн на добавление аватарки Форума (forumAvatar если не строка url)
                                const addForumAvatarAction = new Promise(resolve => {
                                    if(this.forumAvatar && typeof this.forumAvatar !== 'string'){
                                        const dataImg = {
                                            forumId,
                                            image: this.forumAvatar
                                        };
                                        this.saveForumImg(dataImg).then(() => {
                                            resolve();
                                        })
                                            .catch(error => {
                                                errorsAlertApi(error);
                                            });
                                    } else {
                                        resolve();
                                    }
                                });

                                // Удаляем спикеров
                                const deleteSpeakersAction = new Promise(resolve => {
                                    if(this.deletedSpeakers.length){
                                        //Екшн на удаление
                                        let speakerRequests = [];
                                        for(let idx in this.deletedSpeakers){
                                            const data = {
                                                forumId,
                                                speakerId: this.deletedSpeakers[idx]
                                            };
                                            speakerRequests.push(this.deleteSpeakerById(data)
                                                .catch(error => {
                                                    errorsAlertApi(error);
                                                }));
                                        }
                                        Promise.all(speakerRequests).then(() =>{
                                            this.deletedSpeakers = [];
                                            resolve();
                                        });
                                    } else {
                                        resolve();
                                    }
                                });

                                // Добавляем спикеров
                                const addSpeakersAction = new Promise(resolve => {
                                    if(this.addedSpeakers.length){
                                        // Екшн на добавление
                                        let speakerRequests = [];
                                        for (let speaker of this.addedSpeakers) {
                                            const data = {
                                                photo: speaker.photo,
                                                name: speaker.name,
                                                description: speaker.description,
                                                forumId
                                            };
                                            speakerRequests.push(this.saveNewSpeaker(data)
                                                .catch(error => {
                                                    errorsAlertApi(error);
                                                })
                                            );
                                        }
                                        Promise.all(speakerRequests).then(() => {
                                            this.addedSpeakers = [];
                                            resolve();
                                        });
                                    } else {
                                        resolve();
                                    }
                                });

                                // Редактируем спикеров
                                const editSpeakersAction = new Promise(resolve => {
                                    if(Object.keys(this.editedSpeakers).length){
                                        let speakerRequests = [];
                                        for (let speaker of Object.values(this.editedSpeakers)) {
                                            const data = {
                                                name: speaker.name,
                                                description: speaker.description,
                                                speakerId: speaker._id,
                                                forumId
                                            };
                                            speakerRequests.push(this.saveEditSpeaker(data)
                                                .catch(error => {
                                                    errorsAlertApi(error);
                                                })
                                            );
                                            if(speaker.photo){
                                                const speakerPhoto = {
                                                    forumId,
                                                    speakerId: speaker._id,
                                                    photo: speaker.photo
                                                };
                                                speakerRequests.push(
                                                    this.saveSpeakerPhoto(speakerPhoto)
                                                        .catch(error => {
                                                            errorsAlertApi(error);
                                                        })
                                                );
                                            }
                                        }
                                        Promise.all(speakerRequests).then(() => {
                                            this.editedSpeakers = {};
                                            resolve();
                                        });
                                    } else {
                                        resolve();
                                    }
                                });

                                // Удаляем материалы
                                const deleteMaterialsAction = new Promise(resolve => {
                                    if(this.deletedMaterials.length){
                                        let materialRequests = [];
                                        for(let idx in this.deletedMaterials){
                                            const materialId = this.deletedMaterials[idx];
                                            materialRequests.push(this.deleteMaterialById({ forumId, materialId })
                                                .catch(error => {
                                                    errorsAlertApi(error);
                                                }));
                                        }
                                        Promise.all(materialRequests).then(() => {
                                            this.deletedMaterials = [];
                                            resolve();
                                        });
                                    } else {
                                        resolve();
                                    }
                                });

                                // Редактируем материалы
                                const editMaterialsAction = new Promise(resolve => {
                                    if(Object.keys(this.editedMaterials).length){
                                        // Екшн на изменение
                                        let materialRequests = [];
                                        for (let material of Object.values(this.editedMaterials)) {
                                            const data = {
                                                _id: material._id,
                                                title: material.title,
                                                url: material.url,
                                                icon: material.icon,
                                                forumId
                                            };
                                            materialRequests.push(this.editMaterialById(data)
                                                .catch(error => {
                                                    errorsAlertApi(error);
                                                })
                                            );
                                        }
                                        Promise.all(materialRequests).then(() => {
                                            this.editedMaterials = {};
                                            resolve();
                                        });
                                    } else {
                                        resolve();
                                    }
                                });

                                const addMaterialsAction = new Promise(resolve => {
                                    // Добавляем материалы ивента
                                    if(this.addedMaterials.length){
                                        // Екшн на добавление
                                        let materialRequests = [];
                                        for (let material of this.addedMaterials) {
                                            const data = {
                                                title: material.title,
                                                url: material.url,
                                                icon: material.icon,
                                                forumId
                                            };
                                            materialRequests.push(this.saveMaterials(data)
                                                .catch(error => {
                                                    errorsAlertApi(error);
                                                })
                                            );
                                        }
                                        Promise.all(materialRequests).then(() => {
                                            this.addedMaterials = [];
                                            resolve();
                                        });
                                    } else {
                                        resolve();
                                    }
                                });

                                Promise.all([
                                    addForumAvatarAction,
                                    deleteSpeakersAction,
                                    addSpeakersAction,
                                    editSpeakersAction,
                                    deleteMaterialsAction,
                                    editMaterialsAction,
                                    addMaterialsAction
                                ]).then(() => {
                                    this.$emit('updateDataForum', forumId);
                                    this.isBusy = false;
                                    this.$swal({
                                        icon: 'success',
                                        text: 'Данные ивента успешно изменены',
                                        confirmButtonText: 'Ok'
                                    });
                                });
                            })
                                .catch(error => {
                                    errorsAlertApi(error);
                                    this.isBusy = false;
                                });

                        } else {
                            // Добавляем новый форум
                            // Екшн на сохранения форума для получения forum id и затем екшн для сохранения спикеров
                            this.saveNewForum(this.forum).then(forum => {
                                const forumId = forum._id;
                                this.forum.forumId = forumId;

                                // Добавляем спикеров ивента
                                const addSpeakersAction = new Promise(resolve => {
                                    if(this.speakers.length){
                                        // Один энд поинт - один спикер
                                        let speakerRequests = [];
                                        for (let speaker of this.speakers) {
                                            const data = {
                                                photo: speaker.photo,
                                                name: speaker.name,
                                                description: speaker.description,
                                                forumId
                                            };
                                            speakerRequests.push(this.saveNewSpeaker(data)
                                                .catch(error => {
                                                    errorsAlertApi(error);
                                                })
                                            );
                                        }
                                        Promise.all(speakerRequests).then(() => {
                                            resolve();
                                            this.speakers = [];
                                        });
                                    } else {
                                        resolve();
                                    }
                                });

                                // Добавляем материалы ивента
                                const addMaterialsAction = new Promise(resolve => {
                                    if(this.materials.length){
                                        // Екшн на добавление
                                        let materialRequests = [];
                                        for (let material of this.materials) {
                                            const data = {
                                                title: material.title,
                                                url: material.url,
                                                icon: material.icon,
                                                forumId
                                            };
                                            materialRequests.push(this.saveMaterials(data)
                                                .catch(error => {
                                                    errorsAlertApi(error);
                                                })
                                            );
                                        }
                                        Promise.all(materialRequests).then(() => {
                                            resolve();
                                            this.materials = [];
                                        });
                                    } else {
                                        resolve();
                                    }
                                });

                                //Екшн на добавление аватарки Форума
                                const addAvatarAction = new Promise(resolve => {
                                    if(this.forumAvatar){
                                        const dataImg = {
                                            forumId,
                                            image: this.forumAvatar
                                        };
                                        this.saveForumImg(dataImg).then(() => {
                                            resolve();
                                        })
                                            .catch(error => {
                                                errorsAlertApi(error);
                                            });
                                    } else {
                                        resolve();
                                    }
                                });

                                Promise.all([
                                    addSpeakersAction,
                                    addMaterialsAction,
                                    addAvatarAction
                                ]).then(() => {
                                    this.$bvModal.show('success-forum');
                                    this.isBusy = false;
                                });
                            }).catch(error => {
                                errorsAlertApi(error);
                                this.isBusy = false;
                            });
                        }
                    }
                });
            }
        },
        watch: {
            'rangeDateTime.dateStart'(date){
                if(date){
                    this.forum.dateStart = date;
                }
            },
            'rangeDateTime.dateEnd'(date){
                this.forum.dateEnd = date;
            },
        },
        mounted(){
            if(this.isEdit){
                this.getEditData;
            }
        },
        data(){
            return {
                rangeDateTime: {
                    dateStart: null,
                    dateEnd: null
                },
                scenes: [],
                prices: [],

                speakers: [],
                displaySpeakers: [],
                editedSpeakers: {},
                deletedSpeakers: [],
                addedSpeakers: [],

                materials: [],
                editedMaterials: {},
                deletedMaterials: [],
                addedMaterials: [],

                latitude: null,
                longitude: null,

                forumAvatar: null,

                isBusy: false,

                shortTitle: null,
                coordinates: null,
                forum: {
                    title: null,
                    enTitle: null,
                    shortTitle: null,
                    description: null,
                    top: true,
                    draft: true,
                    price: [],
                    location: null,
                    dateStart: null,
                    rooms: []
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .custom-radio{
        display: inline-block;
        margin: 5px 20px 5px 0;
    }
    .scene-box,.speaker-box{
        margin-bottom: 30px;
    }
    .material-box,.location-box,.price-box{
        border-bottom: 1px solid #f0f0f0;
        padding: 0 0 15px;
        margin-bottom: 20px;
    }
    .btn-txt{
        margin-left: 5px;
    }
    .picker-cell {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
        padding-right: 15px;
        padding-left: 15px;
    }
    .type-cell {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
        padding-right: 15px;
        padding-left: 15px;
    }
    @media screen and (max-width: 1600px){
        .picker-cell, .type-cell{
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
</style>