import { render, staticRenderFns } from "./EditPrice.vue?vue&type=template&id=8843cd86&scoped=true&"
import script from "./EditPrice.vue?vue&type=script&lang=js&"
export * from "./EditPrice.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8843cd86",
  null
  
)

export default component.exports