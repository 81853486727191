var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row align-items-start"},[_c('ValidationProvider',{staticClass:"col-xl-6",attrs:{"rules":"required","name":"Дата и время начала ивента","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Дата и время начала ивента")]),_c('date-picker',{staticClass:"date-time-picker",attrs:{"input-class":errors[0] ? 'form-control is-invalid' : (valid ? 'form-control is-valid' : 'form-control'),"type":"datetime","format":"DD.MM.YYYY HH:mm","value-type":"timestamp","placeholder":"__.__.____ __:__","default-value":new Date().setHours(new Date().getHours() + 1),"disabled-date":_vm.disableBeforeToday,"disabled-time":_vm.disableAfterOneHourToday},model:{value:(_vm.range.dateStart),callback:function ($$v) {_vm.$set(_vm.range, "dateStart", $$v)},expression:"range.dateStart"}}),_c('b-form-input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"state":errors[0] ? false : (valid ? true : null),"type":"date"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}])}),_c('ValidationProvider',{staticClass:"col-xl-6",attrs:{"rules":"","name":"Дата и время окончания ивента","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var changed = ref.changed;
return _c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Дата и время окончания ивента")]),_c('date-picker',{staticClass:"date-time-picker",attrs:{"input-class":errors[0] ? 'form-control is-invalid' : (changed && valid ? 'form-control is-valid' : 'form-control'),"type":"datetime","format":"DD.MM.YYYY HH:mm","value-type":"timestamp","placeholder":"__.__.____ __:__","disabled":!_vm.range.dateStart,"disabled-date":_vm.disableBeforeDateStart,"disabled-time":_vm.disableBeforeTimeStart,"default-value":_vm.defaultValueDateEnd},model:{value:(_vm.range.dateEnd),callback:function ($$v) {_vm.$set(_vm.range, "dateEnd", $$v)},expression:"range.dateEnd"}}),_c('b-form-input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"state":errors[0] ? false : (valid ? true : null),"type":"date"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }