<template>
    <b-modal id="edit-scene" size="lg" centered hide-footer>
        <div class="modal-wrapper">
            <div class="h1">Информация о сцене</div>
            <scene-form :isEdit="true" :scenes="scenes" :sceneData="sceneData" @editScene="editScene"/>
        </div>
    </b-modal>
</template>

<script>
    import SceneForm from '@/components/forms/scene/SceneForm'

    export default {
        name: "EditScene",
        props: {
            scenes: {
                type: Array,
                required: true
            },
            sceneData: {
                type: Object,
                required: true
            }
        },
        components: {
            SceneForm
        },
        methods: {
            editScene(scene){
                this.$emit('editScene',scene);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .h1{
        text-align: center;
        margin-bottom: 30px;
    }
</style>