<template>
    <div class="scene">
        <div class="modal-form" v-if="isEdit">
            <div>
                <div class="form-group">
                    <b-form-input
                        v-model="scene.ua"
                        placeholder="Название сцены на украинском"
                        :class="!isValidSceneUa ? 'is-invalid' : ''"
                    ></b-form-input>
                    <div v-if="!isValidSceneUa" class="invalid-feedback">Введите название сцены на украинском (не менее 3 символов)</div>
                </div>
                <div class="form-group">
                    <b-form-input
                        v-model="scene.en"
                        placeholder="Название сцены на английском"
                        :class="!isValidSceneEn ? 'is-invalid' : ''"
                    ></b-form-input>
                    <div v-if="!isValidSceneEn" class="invalid-feedback">Введите название сцены на английском (не менее 3 символов)</div>
                </div>
                <button type="submit" class="btn btn-primary w-100" @click="handleEditScene">Сохранить</button>
            </div>
        </div>

        <div v-else>
            <b-form v-if="scenes.length < 3">
                <div class="form-group">
                    <label v-if="!scenes.length">Сцены ивента</label>
                    <div class="row">
                        <div class="col-md-6">
                            <b-form-input
                                v-model="scene.ua"
                                placeholder="Название сцены на украинском"
                                :class="!isValidSceneUa ? 'is-invalid' : ''"
                            ></b-form-input>
                            <div v-if="!isValidSceneUa" class="invalid-feedback">Введите название сцены на украинском (не менее 3 символов)</div>
                        </div>
                        <div class="col-md-6">
                            <b-form-input
                                v-model="scene.en"
                                placeholder="Название сцены на английском"
                                :class="!isValidSceneEn ? 'is-invalid' : ''"
                            ></b-form-input>
                            <div v-if="!isValidSceneEn" class="invalid-feedback">Введите название сцены на английском (не менее 3 символов)</div>
                        </div>
                    </div>
                </div>
                <button type="button" class="add-btn" @click="handleAddScene">
                    <b-icon icon="plus-circle-fill"></b-icon>
                    <span>Добавить сцену</span>
                </button>
            </b-form>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SceneForm",
        props: {
            isEdit: {
                type: Boolean,
                required: true
            },
            scenes: {
                type: Array,
                required: true
            },
            sceneData: {
                type: Object,
                required: false
            }
        },
        computed: {
            isValidForm(){
                // Валидация
                if(!this.scene.ua || this.scene.ua.length < 3){
                    this.isValidSceneUa = false;
                }

                if(!this.scene.en || this.scene.en.length < 3){
                    this.isValidSceneEn = false;
                }

                if(!this.isValidSceneUa || !this.isValidSceneEn){
                    return false;
                }

                return true;
            }
        },
        watch: {
            sceneData(){
                this.parseSceneData()
            },
            'scene.ua'(value) {
                if(value && value.length >= 3){
                    this.isValidSceneUa = true;
                }
            },
            'scene.en'(value) {
                if(value && value.length >= 3){
                    this.isValidSceneEn = true;
                }
            }
        },
        methods: {
            parseSceneData(){
                if(this.sceneData){
                    this.scene.ua = this.sceneData.scene.split(',')[0];
                    this.scene.en = this.sceneData.scene.split(',')[1];
                } else {
                    this.scene.ua = null;
                    this.scene.en = null
                }
            },
            // Add scene (max 3)
            handleAddScene(){
                if(this.isValidForm){
                    this.$emit('addScene', `${this.scene.ua},${this.scene.en}`);

                    this.scene.ua = null;
                    this.scene.en = null;
                    this.isValidSceneUa = true;
                    this.isValidSceneEn = true;
                }
            },
            handleEditScene(){
                if(this.isValidForm){
                    const scene = {
                        id: this.sceneData.id,
                        scene: `${this.scene.ua},${this.scene.en}`
                    };
                    this.$emit('editScene',scene);
                    this.$bvModal.hide('edit-scene');
                }
            }
        },
        created() {
            this.parseSceneData()
        },
        data(){
            return{
                isValidSceneUa: true,
                isValidSceneEn: true,
                scene: {
                    ua: null,
                    en: null
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.scene{
    .invalid-feedback{
        display: block;
    }
}
</style>