<template>
    <div>
        <scene-list v-if="scenes.length" :scenes="scenes" @editScene="editScene" @deleteScene="deleteScene"/>
        <scene-form :isEdit="false" :scenes="scenes" @addScene="addScene"/>
    </div>
</template>

<script>
    import SceneList from '@/components/forms/scene/SceneList'
    import SceneForm from '@/components/forms/scene/SceneForm'

    export default {
        name: "Scenes",
        components: {
            SceneList,
            SceneForm
        },
        props: {
            scenes: {
                type: Array,
                required: true
            }
        },
        methods: {
            addScene(scene){
                this.$emit('addScene', scene);
            },
            editScene(scene){
                this.$emit('editScene',scene);
            },
            deleteScene(id){
                this.$emit('deleteScene', id);
            }
        }
    }
</script>

<style scoped>

</style>