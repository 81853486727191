<template>
    <b-modal id="success-forum" centered hide-footer hide-header no-close-on-esc no-close-on-backdrop>
        <div class="text-center">
            <div class="swal2-icon swal2-success swal2-icon-show" style="display: flex;">
                <div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);"></div>
                <span class="swal2-success-line-tip"></span> <span class="swal2-success-line-long"></span>
                <div class="swal2-success-ring"></div> <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);"></div>
                <div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);"></div>
            </div>
            <div class="modal-wrap">
                <div class="mb-3">
                    Форум <br>
                    <span class="title">{{forum.title}}</span> <br>
                    успешно
                    <span v-if="forum.draft">создан как черновик</span>
                    <span v-else>опубликован</span>
                </div>
                <div><a href="#" @click="handleScrollToProgram">Добавить Программы Ивента</a></div>
                <div><a href="#" @click="handleAddNewForum">Создать новый ивент</a></div>
            </div>
        </div>
    </b-modal>
</template>

<script>
    export default {
        name: "SuccessForum",
        props: {
            forum: {
                type: Object
            }
        },
        methods: {
            handleScrollToProgram(e){
                // Скролл на добавление Программ форума
                e.preventDefault();
                this.$router.push({
                    name: 'forum-edit',
                    params: { forumId: this.forum.forumId },
                    hash: '#eventAnchor'
                });
                this.$bvModal.hide('success-forum');
            },
            handleAddNewForum(e){
                // Скролл к началу формы Форума и эмит на сброс формы
                e.preventDefault();
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
                this.$emit('reset');
                this.$bvModal.hide('success-forum');
            },
        },
    }
</script>

<style lang="scss" scoped>
    .title{
        font-size: 30px;
        line-height: 1.2;
        font-weight: 600;
        margin-bottom: 20px;
    }
    .modal-wrap{
        font-size: 18px;
    }
</style>